import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Sidebar, TextInput } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaUserCircle } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import FullDate from "../../_helpers/date";
import { getCustomerChat, getMessage, postSingleChat } from "../../apis";
const Message = () => {
  const navigate = useNavigate();
  const [newMessage, setNewMessage] = useState("");
  const { customerId } = useParams();
  const queryClient = useQueryClient();
  const chatEndRef = useRef(null);
  useEffect(() => {
    if (chatEndRef?.current) {
      chatEndRef?.current.scrollIntoView({ behavior: "auto" });
    }
  });
  // eslint-disable-next-line
  const { data } = useQuery({
    queryKey: ["chat"],
    queryFn: () => getCustomerChat(),
  });

  // eslint-disable-next-line
  const { data: message } = useQuery({
    queryKey: ["message", customerId],
    queryFn: getMessage(customerId),
    enabled: !!customerId,
    refetchInterval: 30000,
  });
  const { register, handleSubmit } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });
  const { mutate } = useMutation({
    mutationKey: ["chat"],
    mutationFn: postSingleChat,
    onSuccess: () => {
      queryClient.invalidateQueries(["message", customerId]);
    },
  });

  const handleMessageSubmit = (data) => {
    if (data?.message.length > 0) {
      mutate({ data, id: customerId });
      setNewMessage("");
    }
  };

  return (
    <div className="h-screen">
      <section className=" h-[95%] flex">
        <div className="grow flex flex-col space-y-4 w-full relative">
          <h2 className="text-2xl font-semibold rounded-xl py-2 px-4text-primary">
            Messages
          </h2>
          <div className="grow overflow-auto ScrollbarHide">
            {data?.data?.results?.map((result, index) => (
              <div key={index}>
                <>
                  {result?._id === message?._id &&
                    message?.messages?.map((text, index) => (
                      <>
                        {text.sender.isCustomer === true ? (
                          <div>
                            <p className="p-4 text-center">
                              {FullDate(text?.createdAt)}
                            </p>

                            <div
                              className="relative flex items-center gap-x-4 text-xl message"
                              ref={
                                index === message.messages.length - 1
                                  ? chatEndRef
                                  : null
                              }
                            >
                              <div className="flex flex-col gap-y-2 w-fit">
                                <div className="flex items-center gap-x-2">
                                  <div className="userProfile">
                                    <FaUserCircle className="h-8 w-8 text-primary cursor-pointer" />
                                  </div>
                                  <p className="py-1 my-1 px-4 bg-slate-200 rounded-full text-base">
                                    {text.message}
                                  </p>
                                </div>
                                {text?.attachedProperty && (
                                  <div className="rounded-xl shadow-md w-min">
                                    <div className="w-80">
                                      <img
                                        src="/assets/bharatpur.jpg"
                                        alt={text?.attachedProperty?.name}
                                        className="w-full h-full rounded-t-xl"
                                      />
                                    </div>
                                    <div className="flex flex-col p-4">
                                      <div className="flex justify-between items-center">
                                        <span className="text-base capitalize font-semibold text-lime-700">
                                          {text.attachedProperty?.name}
                                        </span>
                                        <span className="text-sm font-semibold text-slate-700">
                                          {text.attachedProperty?.propertyId}
                                        </span>
                                      </div>
                                      <p className="text-sm font-semibold text-slate-700">
                                        {text.attachedProperty?.category}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          text?.message?.length > 0 && (
                            <div>
                              <p className="p-4 text-center">
                                {FullDate(text?.createdAt)}
                              </p>

                              <div
                                className="relative flex items-center flex-end justify-end gap-x-4 text-xl pt-2 message"
                                ref={
                                  index === message.messages.length - 1
                                    ? chatEndRef
                                    : null
                                }
                              >
                                <p className="py-1 my-1 px-4 bg-primary text-white rounded-full text-base">
                                  {text.message}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    ))}
                </>
                <div ref={chatEndRef}></div>
              </div>
            ))}
          </div>
          <form
            className="flex gap-x-1 items-center"
            onSubmit={handleSubmit(handleMessageSubmit)}
          >
            <TextInput
              id="message"
              type="text"
              placeholder="Enter Message"
              className="grow"
              value={newMessage}
              {...register("message")}
              disabled={!customerId}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button
              type="submit"
              className={["text-sky-500", "disabled:text-neutral-400"].join(
                " "
              )}
              disabled={!customerId}
            >
              <IoMdSend className="text-primary" fontSize={"30px"} />
            </button>
          </form>
        </div>
        <Sidebar className=" min-w-[20vw] shadow-md rounded-3xl overflow-hidden m-3 sticky top-4 ">
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              {data?.data?.results.map((user) => (
                <Sidebar.Item
                  onClick={() =>
                    navigate(`/admin/message/${user?.customer?._id}`)
                  }
                  className={[
                    "hover:bg-primary/20",
                    `${customerId === user?.customer?._id && "bg-primary/40"}`,
                  ].join(" ")}
                >
                  <div className="flex items-center gap-x-4 text-base">
                    <FaUserCircle className="h-12 w-12 text-primary" />
                    <div className="flex flex-col">
                      <span> {user?.customer?.name}</span>

                      <span>{user?.customer?.phone}</span>
                    </div>
                  </div>
                </Sidebar.Item>
              ))}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </section>
    </div>
  );
};

export default Message;
